.spotify-title {
  color: white;
  font-size: 20px;
  align-self: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
  z-index: 1;
}

@media only screen and (min-width: 768px) {
  .spotify-title {
    grid-row: 5;
    grid-column: 3 / span 2;

    align-self: end;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1366px) {
  .spotify-title {
    grid-row: 1;
    grid-column: 2 / span 2;
    margin-top: auto;
  }
}
