.project-button {
  display: block;
  width: 100%;
  height: 15.625rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: 1;
  align-self: start;
}

.project-name {
  visibility: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  text-align: center;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 30px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: center;
}

.project-button:hover > .project-name {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: 200ms;
  -webkit-transition-timing-function: ease-in-out;
  transition: 200ms;
  transition-timing-function: ease-in-out;
  z-index: 2;
}

.project-button:hover > .project-image {
  transform: scale(1.05);  
  -webkit-transition: 1500ms;
  -webkit-transition-timing-function: ease-in-out;
  transition: 1500ms;
  transition-timing-function: ease-in-out;
}

@media only screen and (min-width: 768px) {
  .project-button {
    width: 100%;
    height: 100%;
  }

  .project-1 {
    grid-row: 2 / span 2;
    grid-column: 2 / span 3;
  }

  .project-2 {
    grid-row: 2 / span 2;
    grid-column: 6 / span 3;
  }

  .project-3 {
    grid-row: 2 / span 2;
    grid-column: 10 / span 3;
  }
}

@media only screen and (min-width: 1366px) {
  .project-button {
    width: 100%;
    height: 80%;
    align-self: end;
  }

  .project-1 {
    grid-row: 2 / span 2;
    grid-column: 2 / span 3;
  }

  .project-2 {
    grid-row: 2 / span 2;
    grid-column: 6 / span 3;
  }

  .project-3 {
    grid-row: 2 / span 2;
    grid-column: 10 / span 3;
  }
}
