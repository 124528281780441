.footer-name {
  grid-row: 5;
  grid-column: 1 / span 5;
  justify-self: center;
  align-self: end;
  margin-bottom: 0rem;
  color: white;
  z-index: 1;
}

.footer-copy {
  color: #2eff90;
}

@media only screen and (min-width: 768px) {
  .footer-name {
    grid-row: 6;
    grid-column: 1 / span 12;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1366px) {
  .footer-name {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
