.media-button {
  grid-row: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  z-index: 1;
}

.icon {
  height: 40px;
  border-radius: 50%;
  padding: 1rem;
  overflow: visible;
}

.icon:hover {
  cursor: pointer;
  -webkit-transition: 200ms;
  -webkit-transition-timing-function: ease-in-out;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}

.green-icon {
  fill: #2eff90;
  background-color: #1e1d1d;
  border: 2px solid #2eff90;
}

.green-icon:hover {
  fill: #1e1d1d;
  border-color: #1e1d1d;
  background-color: #2eff90;
}

.blue-icon {
  fill: #5edcff;
  background-color: #1e1d1d;
  border: 2px solid #5edcff;
}

.blue-icon:hover {
  fill: #1e1d1d;
  border-color: #1e1d1d;
  background-color: #5edcff;
}

.yellow-icon {
  fill: #fff056;
  background-color: #1e1d1d;
  border: 2px solid #fff056;
}

.yellow-icon:hover {
  fill: #1e1d1d;
  border-color: #1e1d1d;
  background-color: #fff056;
}

.top {
  grid-column: 2;
  margin-right: 100%;
}

.middle {
  grid-column: 3;
}

.bottom {
  grid-column: 4;
  margin-left: 100%;
}

.label {
  display: none;
  color: white;
  font-size: 35px;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .top {
    grid-column: 2 / span 3;
    margin-right: 0;
  }

  .middle {
    grid-column: 6 / span 3;
  }

  .bottom {
    grid-column: 10 / span 3;
    margin-left: 0;
  }

  .label {
    display: block;
  }
}

@media only screen and (min-width: 1366px) {
  .media-button {
    grid-column: 10 / span 2;
    flex-direction: row;
  }

  .label {
    margin: 0 auto 0 2rem;
  }

  .top {
    grid-row: 2;
    align-self: end;
    margin: 0;
  }

  .middle {
    grid-row: 3;
    margin: 0;
  }

  .bottom {
    grid-row: 4;
    align-self: start;
    margin: 0;
  }
}
