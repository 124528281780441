.project-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-section {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: repeat(6, 20%);
}

@media only screen and (min-width: 768px) {
  .project-section {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(3, 5rem);
    grid-auto-rows: 10rem;
  }

  .about-section {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(3, 10rem);
  }

  .contact-section {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

@media only screen and (min-width: 1366px) {
  .project-section {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(4, 10rem);
    grid-auto-rows: 10rem;
  }

  .about-section {
    grid-template-rows: repeat(4, 10rem);
  }
}
