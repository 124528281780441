.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .app {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
}
