.particle-canvas {
  display: block;
  background: #0e0c0c;
  position: fixed;
  z-index: -1;
}

@media only screen and (min-width: 768px) {
  .particle-canvas {
    width: 100% !important;
  }
}
