.name-container {
  grid-column: 1 / span 3;
  grid-row: 3 / span 1;
  width: fit-content;
  color: white;
  align-self: center;
  justify-self: center;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 5rem;
  z-index: 1;
}

.name {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 60px;
}

.title {
  font-size: 20px;

  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .name-container {
    grid-column: 1 / span 13;
    grid-row: 3 / span 1;
    margin-bottom: 10rem;
  }

  .name {
    font-size: 80px;
  }

  .title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1366px) {
  .name-container {
    grid-column: 5 / span 5;
    grid-row: 2 / span 1;

    align-self: center;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
  }
}
