.colored-line {
  width: 80%;
  height: 8px;

  color: #2eff90;
  background-color: #2eff90;

  border: none;
  margin-bottom: 0 !important;
  align-self: flex-start;
}

@media only screen and (min-width: 768px) {
  .colored-line {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0 !important;
  }

  .name-align {
    margin-left: auto;
    margin-right: auto;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }

  .right {
    margin-left: auto;
  }
}

@media only screen and (min-width: 1366px) {
  .colored-line {
    width: 80%;
    height: 8px;

    color: #2eff90;
    background-color: #2eff90;

    border: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0 !important;
  }

  .name-align {
    margin-left: 0;
    margin-right: 0;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }

  .right {
    margin-left: auto;
  }
}
