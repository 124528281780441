.project-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  top: 0;
  width: 100%;
  height: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.modal-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #1e1d1d;
}

.modal-exit {
  position: absolute;
  height: 2rem;
  align-self: flex-end;
  padding: 1rem 1rem 1rem 1rem;
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.8);
  stroke: black;
}

.modal-exit:hover {
  fill: rgba(255, 255, 255, 1.0);
}

.modal-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35%;
  overflow: hidden;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.75);
}

.modal-image {
  width: 140%;
  height: auto;
}

.modal-project-name {
  align-self: center;
  text-align: center;
  margin-bottom: 0;
  width: auto;
}

.modal-project-info {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 18px;
  text-align: center;
}

.modal-button {
  display: flex;
  justify-content: center;
  align-self: center;
  color: #1e1d1d;
  text-decoration: none;
  font-size: 20px;
  background-color: #5edcff;
  width: 8rem;
  height: 3rem;
  border: none;
  margin-bottom: 3rem;
}

.modal-button:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  -webkit-transition: 200ms;
  -webkit-transition-timing-function: ease-in-out;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}

.modal-button-image {
  width: 1rem;
  height: auto;
}

.modal-button-text {
  align-self: center;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1rem;
}

@media only screen and (min-width: 768px) {
  .modal-box {
    width: 70%;
    height: 70%;
  }

  .modal-project-name {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1366px) {
  .modal-box {
    width: 50%;
    height: 70%;
  }

  .modal-project-info {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 70%;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
