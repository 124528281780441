.section-title {
  width: fit-content;
  font-size: 40px;
  color: white;
  margin-bottom: 4rem;
  grid-column: 2 / span 3;
  justify-self: center;
}

@media only screen and (min-width: 768px) {
  .left {
    grid-column: 2 / span 2;
    justify-self: start;
  }

  .right {
    grid-row: 1;
    grid-column: 9 / span 4;
    align-items: flex-end;
    align-self: end;
  }
}

@media only screen and (min-width: 1366px) {
  .left {
    grid-column: 2 / span 2;
    justify-self: start;
  }

  .right {
    display: flex;
    flex-direction: column;
    grid-row: 1;
    grid-column: 11 / span 2;
    align-items: flex-end;
    align-self: center;
  }
}
