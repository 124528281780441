.contact {
  display: flex;
  flex-direction: column;

  grid-row: 2 / span 3;
  grid-column: 1 / span 5;

  /*background-color: #1E1D1D;*/
  z-index: 1;
}

.sender-name {
  height: 7%;
  margin: 0rem 1rem 1rem 1rem;
  padding: 0.5rem;
  background-color: #1e1d1d;
  border: 0;
  color: white;

  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.sender-email {
  height: 7%;
  margin: 0rem 1rem 1rem 1rem;
  padding: 0.5rem;
  background-color: #1e1d1d;
  border: 0;
  color: white;

  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.email-box {
  height: 25rem;
  margin: 0rem 1rem 1rem 1rem;
  padding: 0.5rem;
  background-color: #1e1d1d;
  border: 0;
  resize: none;
  color: white;

  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.send-button {
  align-self: center;
  color: #1e1d1d;
  background-color: #2eff90;
  font-size: 20px;
  border: none;
  width: 8rem;
  min-height: 3rem;
  cursor: pointer;
}

.send-button:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  -webkit-transition: 200ms;
  -webkit-transition-timing-function: ease-in-out;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}

@media only screen and (min-width: 768px) {
  .contact {
    grid-column: 2 / span 11;
  }

  .sender-name {
    margin: 0 0 1rem 0;
  }

  .sender-email {
    margin: 0 0 1rem 0;
  }

  .email-box {
    height: 80%;
    margin: 0 0 1rem 0;
  }

  .contact-text {
    margin: 0;
  }

  .send-button {
    align-self: flex-end;
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 1366px) {
  .contact {
    grid-column: 2 / span 6;
  }

  .send-button {
    align-self: flex-end;
    margin-bottom: 0;
  }
}
