.landing {
  display: flex;
  flex-direction: column;

  min-width: 100%;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .landing {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 1366px) {
  .landing {
    grid-template-rows: repeat(3, 1fr);
  }
}
