.portrait {
  width: 15rem;
  height: 15rem;
  margin-top: 5rem;

  align-self: center;
  justify-self: center;
  border-radius: 50%;
  object-fit: cover;

  z-index: 1;
}

@media only screen and (min-width: 768px) {
  .portrait {
    width: 18.75rem;
    height: 18.75rem;
    margin-top: 0;

    grid-column: 6 / span 3;
    grid-row: 2 / span 1;
  }
}

@media only screen and (min-width: 1366px) {
  .portrait {
    grid-column: 10 / span 3;
    grid-row: 2 / span 1;
  }
}
