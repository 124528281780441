.content-area {
  width: 100%;

  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.content-area::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 768px) {
  .content-area {
    width: calc(100% - 4rem);
    height: 100%;

    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .content-area::-webkit-scrollbar {
    display: none;
  }
}
