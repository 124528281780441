.about-text {
  width: 90%;
  color: white;
  font-size: 25px;
  align-self: center;
  text-align: center;
  z-index: 1;
}

.underline {
  text-decoration: underline dashed #5edcff;
  cursor: help;
}

@media only screen and (min-width: 768px) {
  .about-text {
    grid-row: 2 / span 3;
    grid-column: 2 / span 11;

    width: 100%;
    font-size: 35px;
  }
}

@media only screen and (min-width: 1366px) {
  .about-text {
    grid-row: 2 / span 3;
    grid-column: 6 / span 7;
    align-self: start;
  }
}
