.menubar {
  height: 2rem;
  align-self: flex-end;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  margin-right: 1.5rem;
  cursor: pointer;
  background-color: #1e1d1d;
  z-index: 2;
}

.logo {
  display: none;
}

.close-button {
  height: 2rem;
  align-self: flex-end;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  margin-right: 1.5rem;
  cursor: pointer;
  background-color: #1e1d1d;
  z-index: 2;
}

.navbar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #1e1d1d;
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .menubar {
    display: none;
  }

  .logo {
    display: block;
    margin: 0;
    width: 3rem;
    align-self: center;
    top: 1rem;
    position: absolute;
  }

  .navbar {
    display: flex;
    justify-content: center;
    width: 4rem;
    height: 100%;
    position: static;
    background-color: #1e1d1d;
  }
}
