.spotify-widget {
  align-self: center;
  z-index: 1;
}

@media only screen and (min-width: 768px) {
  .spotify-widget {
    grid-row: 5 / span 2;
    grid-column: 5 / span 6;
    margin-top: 4rem;

    align-self: start;
  }
}

@media only screen and (min-width: 1366px) {
  .spotify-widget {
    grid-row: 2 / span 3;
    grid-column: 2 / span 2;
    margin-top: 0;

    align-self: start;
  }
}
