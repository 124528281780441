.nav-button {
  border: 0;
  width: 100%;
  height: 3rem;
  background-color: #1e1d1d;
  cursor: pointer;
}

.nav-button > * {
  pointer-events: none;
}

.nav-button:hover {
  -webkit-transition: 200ms;
  -webkit-transition-timing-function: ease-in-out;
  transition: 200ms;
  transition-timing-function: ease-in-out;
  background-color: rgb(45, 45, 45);
}

@media only screen and (min-width: 768px) {
  .nav-button {
    border: 0;

    width: 100%;
    height: 80px;

    background-color: #1e1d1d;
  }
}
